require('expose-loader?propTypes!prop-types');
require('expose-loader?react!react');
require('expose-loader?reactDom!react-dom');
require('expose-loader?reactRouter!react-router');
require('expose-loader?reactRouterDom!react-router-dom');
require('expose-loader?reactHistory!history');
require('expose-loader?classnames!classnames');
require('expose-loader?reactErrorBoundary!react-error-boundary');

// To expose globally is only needed because of:
// bv_frontend + 2 webpacks + backbone using window.react
// Once we have only 1 webpack we do not need externals
// https://github.com/rails/webpacker/issues/1322
